import React, { useContext } from "react";
import SEO from "../components/SEO";
import JumbotronSection from "../components/partials/privacycenter/JumbotronSection";
import NoteSection from "../components/partials/privacycenter/NoteSection";
import QuoteSection from "../components/partials/privacycenter/QuoteSection";
import PrivacyByDesignSection from "../components/partials/privacycenter/PrivacyByDesignSection";
import { TranslationContext } from "@src/components/translation/TranslationContext";
import { graphql } from "gatsby";
import ControlSection from "../components/partials/privacycenter/ControlSection";
import StandardsSection from "../components/partials/privacycenter/StandardsSection";
import ResearcherSection from "../components/partials/privacycenter/ResearcherSection";
import ResourcesSection from "../components/partials/privacycenter/ResourcesSection";
import AdvisorsSection from "../components/partials/privacycenter/AdvisorsSection";

const PrivacyCenterPage = ({ data }) => {
  const { quote_one_avatar, quote_two_avatar, quote_three_avatar } = data.directus.page_privacy;
  const t = useContext(TranslationContext);

  return (
    <>
      <SEO
        title="page_titles.privacycenter"
        description="page_descriptions.privacycenter"
        image="https://static.classdojo.com/img/page_privacycenter/privacyCenterFBimage.png"
        twitter={{
          card: "summary_large_image",
          site: "@classdojo",
          creator: "@classdojo",
          title: "ClassDojo Privacy Center",
          description:
            "See how ClassDojo keeps its community safe through privacy by design and complying with FERPA and COPPA",
          image: "https://static.classdojo.com/img/page_privacycenter/privacyCenterFBimage.png",
        }}
      />
      <JumbotronSection />
      <NoteSection />
      <QuoteSection
        image={quote_one_avatar}
        text={t.translate("directus.page_privacycenter.quote_one_text")}
        subtext={t.translate("directus.page_privacycenter.quote_one_signature")}
      />
      <PrivacyByDesignSection />
      <QuoteSection
        ltr
        image={quote_three_avatar}
        text={t.translate("directus.page_privacycenter.quote_three_text")}
        subtext={t.translate("directus.page_privacycenter.quote_three_signature")}
      />
      <ControlSection />
      <QuoteSection
        image={quote_two_avatar}
        text={t.translate("directus.page_privacycenter.quote_two_text")}
        subtext={t.translate("directus.page_privacycenter.quote_two_signature")}
      />
      <StandardsSection />
      <AdvisorsSection />
      <ResourcesSection />
      <ResearcherSection />
    </>
  );
};
export default PrivacyCenterPage;

export const query = graphql`
  query PagePrivacy {
    directus {
      page_privacy {
        quote_one_avatar {
          filename_disk
        }
        quote_two_avatar {
          filename_disk
        }
        quote_three_avatar {
          filename_disk
        }
      }
    }
  }
`;
